@font-face {
    font-family: "Ubuntu-Medium";
    src: url("../public/fonts/Ubuntu/Ubuntu-Medium.woff2");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Ubuntu-Light";
    src: url("../public/fonts/Ubuntu/Ubuntu-Light.woff2");
    font-weight: 400;
    font-display: swap;
}


@font-face {
    font-family: "Ubuntu-Bold";
    src: url("../public/fonts/Ubuntu/Ubuntu-Bold.woff2");
    font-weight: 700;
    font-display: swap;
}