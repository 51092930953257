@import url(./font.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* width: 100%; */
  /* display: flex; */
  background: #131313;
}

App {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #1986EC;
}

i {
  color: #c2c2d1,
}

::-webkit-scrollbar {
  /* display: none; */
}

button {
  color: #1986EC;
  font-weight: 700;
  border: 0;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}

strong {
  color: #1986EC;
}

p {
  font-family: 'Ubuntu-Light'
}

body::-webkit-scrollbar {
  background-color: #131313;
  width: 10px;
  height: 10px;
}

div::-webkit-scrollbar {
  background-color: #131313;
  width: 10px;
  height: 10px;
}

@media (max-width:1024px) {
  body::-webkit-scrollbar {
    display: none !important;
  }
  div::-webkit-scrollbar {
    display: none !important;
  }
}

body::-webkit-scrollbar-thumb {
  background-color: #131313;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

textarea::-webkit-scrollbar {
  background-color: none;
  width: 10px;
  height: 10px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #0d0d0d;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
}

textarea::-webkit-scrollbar-track-piece:start {
  margin-top: 32.5px; 
}

textarea::-webkit-scrollbar-track-piece:end {
  margin-bottom: 32.5px; 
}


